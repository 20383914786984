.module_figma {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
  font-weight: 400;

	@media (max-width: 992px) {
		padding-left: 15px;
	}
}

/* Mobile horizontal content scroll */
@media (max-width: 768px) {
	.module_figma.mobile-layout-horizontal {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		padding-top: 5px !important; /* Avoid strange text cut */

		/* Hide scrollbar for all browsers */
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */

		.row {
			display: inline-flex;
			flex-wrap: nowrap;

			.module_content_column {
				float: none;
				min-width: 75%;
  			box-sizing: border-box;
			}
		}
	}

	.module_figma::-webkit-scrollbar {
		display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
	}
}

.content-area {
  transform-origin: 0 0;

  div {
    position: absolute;
    z-index: 1;

		&.choose-file__hover {
			div {
				position: relative;
			}
		}

		.image {
			position: relative;
			width: 100%;
			height: 100%;

			.hover-default,
			.hover-hover {
				height: 100%;

				video {
					display: block;
					width: 100%;
				}
			}

			div {
				position: relative;
				overflow: hidden;

				&.btn-download-float {
					position: absolute;
				}
			}

			&.with--hover .hover-hover {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: opacity .2s ease-in;
			}
		}

		@media (min-width: 768px) {
			.image.with--hover:hover .hover-hover {
					opacity: 1;
			}
		}
  }

  p {
		line-height: 1;
    padding: 0;
    margin: 0;
    width: 100%;
		overflow-wrap: break-word;
		word-break: break-word;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    image-rendering: auto;
    position: absolute;
  }

  svg {
    cursor: pointer;
  }

	&--mobile {
		width: 375px;
		margin: 0 auto;
	}
}

.content-preview {
	.content-area {
		svg,
		img,
		p {
			cursor: inherit;
		}

		&--mobile {
			width: 375px;
			margin: 0 auto;
		}
	}
}