.module_embed {
    height: 100%;

    &__input {
        border: 1px solid #ddd;
        padding: 30px;
        background-color: #f5f8fa;
        margin-top: 20px;

        input[type=text] {
            width: 100%;
            max-width: 450px;
            padding: 0 15px;
        }
    }

    &__error {
        padding: 10px 0;
        color: #ff0000;
    }

    .loader {
      position: absolute;
      top: 58px;
      left: 510px;

      img {
          width: 22px;
          -webkit-animation: spin 3s linear infinite;
          animation: spin 3s linear infinite;
      }
    }

    &--youtube,
    &--vimeo,
    &--infogram,
    &--gspreadsheet,
    &--embed {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    &--vimeo {
        padding-top: 55.25%;
    }

    &--spotify {
        iframe {
            width: 100%;
        }
    }

    &--facebook {
        .fb-post,
        .fb-post span,
        iframe {
          max-width: 100%;
        }
    }

    .twitter-tweet {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .instagram-media {
        min-width: 219px !important;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0);
    }

    .is-activated {
        .overlay {
            background: rgba(0,0,0,0.5);
        }
    }
}
