.carousel-div {

	display: block;
	position: relative;
	width: 100%;;

	img {
		width: 100%;
		height: auto;
		display: block;
	}
}

.carousel-slider {
	display: block;
	width: calc(100vw - 255px);
  position: relative;
  margin: 0 auto;
  max-width: 1070px;
}

.carousel-slider.full--width {
	max-width: 100%;
}

.carousel-div {

	.background-video-block-ratio-16_9 {
    overflow: hidden;
    aspect-ratio: 16/9;

    video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .background-video-block-ratio-3_2 {
    overflow: hidden;
    aspect-ratio: 3/2;

    video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .background-video-block-ratio-4_3 {
    overflow: hidden;
    aspect-ratio: 4/3;

    video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .background-video-block-ratio-1_1 {
    overflow: hidden;
    aspect-ratio: 1/1;

    video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

	.with--hover {
	  position: relative;

	  .grid-item-wrapper {
	      overflow: hidden;
	      position: relative;
	  }

	  .hover-default {
	      display: block;

	      video {
	          max-width: 100%;
	          display: block;
	      }
	  }

	  .hover-hover {
	      opacity: 0;
	      position: absolute;
	      top: 0;
	      left: 0;
	      width: 100%;
	      height: 100%;
	      transition: opacity 0.2s ease-in;

	      img,
	      video {
	          width: 100%;
	          display: block;
	      }
	  }

	  @media(min-width: 768px) {
	      &:hover {
	          .hover-hover {
	              opacity: 1;
	          }
	      }
	  }
	}


	.image-block {
	  display: block;
	  overflow: hidden;
	  position: relative;

	  img,
    video {
        width: 100%;
        display: block;
    }

		@media(max-width: 792px) {
			margin-right: 0 !important;
    	margin-left: 0 !important;
		}
	}

	.editor-content {
		margin-top: unset;
	}

	.title-wrap {
	   p, h1, h2 {
	      margin: 0;
	  }

	  h1.linebreak-true,
	  h2.linebreak-true,
	  h3.linebreak-true,
	  p.linebreak-true {
	      margin: 0;
	  }

	  p {
	      &.ql-align-justify {
	          text-align: justify;
	      }

	      &.ql-align-center {
	          text-align: center;
	      }

	      &.ql-align-right {
	          text-align: right;
	      }
	  }
	}
}

.btn-switch-slide {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 10;
  left: -100px;
  bottom: -100px;
  background: #eee;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  svg {
    width: 60px;
    height: 78px;
    margin-left: 4px;
    transform: scaleX(-1);
    transition: transform 0.2s ease-in-out;
  }

  &.btn-switch-slide--right {
    svg {
      transform: scaleX(1);
      margin-left: 0;
      margin-right: 4px;
    }
  }
}

.carousel-div-right-btn {
	position: absolute;
	visibility: visible;
	top: 50%;
	margin-top: -32px;
	right: -32px;
	left: unset;
	bottom: unset;
	width: 64px;
	height: 64px;
	opacity: 0;
}

.slick-slide {
	span {
    display: block;
    overflow: hidden;
  }
}

.carousel-div-item {
	overflow: hidden;
}

.layout-padding-1 {

	.slick-slide {
		//margin: 0 15px;
	}

	.slick-list {
		//margin: 0 -15px;
	}
}

.layout-padding-4 {

	.slick-slide {
		//margin: 0 10px;
	}

	.slick-list {
		//margin: 0 -10px;
	}
}

.carousel-div-left-btn {
	position: absolute;
	visibility: visible;
	top: 50%;
	margin-top: -32px;
	left: -32px;
	right: unset;
	bottom: unset;
	width: 64px;
	height: 64px;
	opacity: 0;
}

.carousel-slider.full--width {
	.carousel-div-left-btn {
		left: 25px;
	}

	.carousel-div-right-btn {
		right: 25px;
	}
}

.carousel-div:hover {
	.carousel-div-left-btn, .carousel-div-right-btn {
		opacity: 1;
	}
}

.slide_current_0 {
	.carousel-div-left-btn {
		display: none;
	}
}

.last-slide {
	.carousel-div-right-btn {
		display: none;
	}
}

@media (min-width: 992px) and (max-width: 1320px) {
	.carousel-slider {
		width: 100vw;
	}
}

@media (max-width: 992px)  {
	.carousel-slider {
		width: 100vw;
	}
}


.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}

.slick-track {
	display: flex !important;
}

.mobile-slider {
	.carousel-div-right-btn {
		right: 25px;
		opacity: 1;
	}
	.carousel-div-left-btn {
		left: 25px;
		opacity: 1;
	}
}

@media (max-width: 1385px) {
	.carousel-div-right-btn {
		right: 25px;
	}
	.carousel-div-left-btn {
		left: 25px;
	}
}

.CarouselModule .videoSlide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .videoSlideInner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        video {
            max-height: 90%;
            max-width: 90%;
            height: auto;
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }
}