body {
  overflow: hidden;
  height: 100%;
  position: relative;
}

html {
  position: relative;
  height: 100%;
}

body .app-content {
  overflow-y: auto;
  height: 100%;
  position: relative;
}

body .app-content::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

body .app-content::-webkit-scrollbar-thumb {
  background-color: #d8e1ed;
  border-radius: 10px;
}

body #root,
body .content-wrapper {
  height: 100%;
}

.pswp__img--placeholder--blank {
  background: transparent !important;
}

.pswp__bg {
  background: #1d1d1d !important;
}

.pswp__top-bar {
  background: none !important;
}

.big-menu {
  .brand-content {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.brand-content {
  width: calc(100% - 16.6666666667%);
  padding: 0;
  margin-left: 16.6666666667%;
  opacity: 0;
  transition: all 0.2s;
  min-height: 100vh;
  overflow-x: hidden;
  display: table;

  &.powered--1 {
    padding-bottom: 40px;
  }

  .text-copied {
    margin-left: 0;
  }

  @media(max-width: 992px) {
    width: 100% !important;
    margin-left: 0 !important;
    padding-top: 60px;

    .text-copied {
      margin-left: 0;
    }
  }

  @media(min-width:992px) and (max-width: 1200px) {
    width: calc(100% - 22%);
    margin-left: 22%;
  }

  @media(min-width:992px) and (max-width: 1320px) {
    width: 100% !important;
    margin-left: 0 !important;
    padding-left: 0;

    .text-copied {
      margin-left: 0;
    }
  }

  @media(min-width: 1440px) {
    width: calc(100% - 240px);
    margin-left: 240px;
  }

  &.full-width {
    width: 100% !important;
    margin-left: 0 !important;

    .text-copied {
      margin-left: 0 !important;
    }
  }

  &.fade-in {
    opacity: 1;
  }
}

body.menu--hidden {
  .brand-content {
    width: 100% !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}


.page-content {
  min-height: 1px;
  tab-size: 4;
  -moz-tab-size: 4;

  .module {
    padding: 96px 0;
    position: relative;
    background-size: cover;

    .copy-link {
      display: none;
    }

    &:hover {
      .copy-link {
        display: block;
      }
    }

    &.module--grey {
      background: #f5f5f5;
    }

    &.module--no-padding,
    &.module--nopadding {
      padding: 0;
    }

    &.module--grid-titles,
    &.module--nobottom {
      padding-bottom: 0;
    }

    &.module--notop {
      padding-top: 0;
    }

    &.module--sides {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  h1 {
    color: #16171a;
    line-height: 1.1;
    margin-bottom: 40px;
    margin-top: 0;
  }
}

.module_heading {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
}

.footer-navigation-block {
  width: 100%;
  background-color: #f5f5f5;
  background-size: cover;
  position: relative;

  .footer-dim {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  &__content {
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    position: relative;
  }
}

.footer-navigation-item-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%
}

.previous-page .footer-navigation-item-block {
  justify-content: flex-start
}

.previous-page .footer-navigation-item-block-button {
  margin-right: 30px
}

.next-page .footer-navigation-item-block {
  justify-content: flex-end
}

.next-page .footer-navigation-item-block-button {
  margin-left: 30px
}

.footer-navigation-block.show-next-only .next-page .footer-navigation-item-block {
  justify-content: space-between
}

@media(max-width: 768px) {
  .footer-navigation-block__content {
    .previous-page {
      display: none;
    }
  }
  .footer-navigation-block .next-page .footer-navigation-item-block {
    justify-content: space-between
  }
}

.next-page, .previous-page {
    width: 100%;
    position: relative;
    display: table-row-group;
    vertical-align: bottom;
    line-height: 1.4;

    &__content {
      max-width: 1100px;
      margin: 0 auto;
      position: relative;
      padding: 0;

      &.padding--nobottom {
        padding-bottom: 0;
      }

      &.padding--notop {
        padding-top: 0;
      }

      &.padding--sides {
        padding-top: 0;
        padding-bottom: 0;
      }

        a {
            display: block;
            padding: 24px 15px 10px;
            position: relative;

            svg {
                width: 7px;
                height: 14px;
            }

            span {
                display: block;
                color: #000;
                font-size: 16px;
                font-weight: 300;
            }

            h6 {
                color: #000;
                font-size: 24px;
                font-weight: 400 !important;
                font-family: "Rubik";
                margin: 0;
                padding: 0;
                position: relative;
            }
        }
    }

    &.no--powered {
      .next-page__content {
        a {
          &:after {
            top: calc(50% + 15px);
          }
        }
      }
    }
}


.cookie-link-showing.footer-showing {
  .next-page .next-page__content {
    padding-bottom: 40px;
  }
}

.cookie-link-showing.footer-not-showing {
  .page-content {
    padding-bottom: 40px;
  }
}

.footer-relative {
  position: relative;
}

.powered-by {
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Rubik' !important;
    line-height: 40px;
    letter-spacing: normal;
    background-color: transparent;
    text-align: center;
    display: table-row-group;
    vertical-align: bottom;
    position: absolute;
    bottom: 0;

    .cookies-setting-caller-div {
      display: inline;
    }

    @media(max-width: 1320px) {
        width: 100%;
    }

    img {
      height: 12px;
      margin: -3px 0 0 10px;
    }

    &.full--width {
      width: 100%;
    }

    &.by--cb {
      background-color: #000;
      color: #e3e1df;
      font-family: 'Rubik' !important;
      font-size: 14px !important;
      .cookies-setting-caller-div {
        font-size: 14px !important;
      }
      a {
        color: #e3e1df;
        padding: 5px 10px;
        border-radius: 5px;
        transition: all .2s;

        &:hover {
          background: rgba(255, 255, 255, 0.15) !important;
        }
      }
      .cookies-setting-caller-div:hover {
        background: rgba(255, 255, 255, 0.15) !important;
      }
      span {
        color: #4e5058;
      }
    }

    .cookies-setting-caller-div {
      padding: 5px 10px;
      border-radius: 5px;
      transition: all .2s;
    }
}

body.menu--hidden {
  .next-page,
  .powered-by {
    width: 100%;
  }
}

.editor-content {
    white-space: pre-wrap;

    .ql-align-center {
        text-align: center;
    }

    .ql-align-right {
        text-align: right;
    }

    .ql-align-justify {
        text-align: justify;
    }

    h1 {
        padding: 0;
        margin: 20px 0 0;
        color: #16171a;
        @media(max-width: 992px) {
            margin: 15px 0 0;
        }

        &.linebreak-true {
            margin: 0;
        }
    }

    h2 {
        padding: 0;
        margin: 20px 0 0;
        color: #16171a;
        @media(max-width: 992px) {
            margin: 15px 0 0;
        }

        &.linebreak-true {
            margin: 0;
        }
    }

    p {
        margin: 20px 0 0;
        color: #16171a;
        @media(max-width: 992px) {
            margin: 15px 0 0;
        }

        &.linebreak-true {
            margin: 0;
        }
    }

    ul,
    ol {
        padding: 0 18px 0 0;
        margin: 20px 0 0 18px;

        @media(max-width: 992px) {
          margin: 15px 0 0 18px;
        }

        li {
          position: relative;
          padding-left: 0;
        }
    }

    ul {
      list-style: none !important;

      li {
        &::before {
          content: "•";
          padding-right: 10px;
          font-size: 16px;
          line-height: 16px;
          margin-left: -18px;
        }

        &.linebreak-true {
          &::before {
            display: none;
          }
        }

        &:first-child {
          &::before {
            content: "•";
            padding-right: 10px;
            font-size: 16px;
            line-height: 16px;
            margin-left: -18px;
            display: inline;
          }
        }
      }
    }

    ol {
      list-style: none !important;
    }

    ol li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-num;
      &:before {
        display: inline-block;
      }

      &.linebreak-true {
        counter-increment: none;
        &:before {
          content: "";
        }
      }
    }

    ol li:before {
      content: counter(list-num, decimal) '. ';
      margin-left: -18px;
      position: absolute;
      left: 0;
      letter-spacing: 0 !important;
    }

    a {
      color: inherit;
      text-decoration: none;
      display: inline;
      border-bottom: 1px solid;
    }

    strong {
      font-weight: bold !important;
    }
}

@media (min-width: 769px) {
  .editor-content {
    margin-top: -20px;
  }
}

.editor-content {
  h1.linebreak-true:nth-child(1),
  h2.linebreak-true:nth-child(1),
  p.linebreak-true:nth-child(1) {
    margin-top: 20px !important;
  }
}

.editor-content {
  h1,
  h2,
  p {
    span {
      background-color: transparent !important;
    }
  }
}

$sizes: 200;

@for $i from 1 through $sizes {
  .editor-content .ql-size-#{$i}px {
    font-size: (1 * $i) + px !important;
  }
}

.dynamic-content {
  position: relative;
  max-width: 100%;
  outline: 1px solid transparent !important;
  outline-offset: 0px;
  word-break: break-word;
}

/* Responsive fixes */
@media(max-width: 992px) {
  #masthead-area {
    padding-top: 30px !important;
  }

  .dynamic-content,
  .module_image__image {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 0 !important;
  }

  .module_content_column {
    height: auto !important;
  }

  .next-page__content a h6 {
    font-size: 19px;
  }

  .head-image.head-image--small {
    min-height: 155px;

    #masthead-area {
      min-height: 155px !important;
    }
  }
}

.react-reveal {
  animation-fill-mode: none !important;
}

.app-content {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.body-loaded {
  .app-content {
    opacity: 1;
  }
}

.module-bg-dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
}

.module-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }
}

.spinner-icon {
  width: 14px;
  height: 14px;

  background: url('../../../assets/img/auth-spinner.svg');
  background-size: cover;

  -webkit-animation: rotation 1s infinite linear;
  -moz-animation:    rotation 1s infinite linear;
  -o-animation:      rotation 1s infinite linear;
  animation:         rotation 1s infinite linear;

  &.spinner-icon--20 {
      width: 20px;
      height: 20px;
      margin: -13px auto 0;
      border-radius: 20px;
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.btn-download-float {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: -100px;
  bottom: -100px;
  background: #eee;
  width: 64px;
  border-radius: 64px;
  text-align: center;
  height: 64px;
  line-height: 60px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  svg {
    width: 28px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
  }
}

.text-copied {
  position: fixed;
  z-index: 11;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 134px;
  height: 32px;
  line-height: 30px;
  border-radius: 32px;
  font-size: 12px;
  font-family: 'Rubik' !important;
  letter-spacing: normal;
  padding-left: 14px;
  color: #fff;
  text-align: center;
  background: #0c56ff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
    bottom: 30px;
  }

  &:before {
    content: "Copied";
  }

  svg {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    left: 11px;
    transform: translateY(-50%);
  }
}

.attachmentModalOpened {
   @media(min-width: 1440px) {
    .text-copied {
      margin-left: 0;
    }
   }
}

.file-info {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  z-index: 10;
  width: 300px;
  box-shadow: 0 0 27px rgba(3, 16, 94, 0.2);
  border-radius: 5px;
  background-color: #f6fafb;
  transition: top 0.2s ease-in, opacity 0.2s ease-in;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  visibility: hidden;
  opacity: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f6fafb;
  }

  .file-info__title {
      height: 53px;
      border-radius: 5px 5px 0 0;
      background-color: #0c56ff;
      line-height: 53px;
      overflow: hidden;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      text-transform: lowercase;
  }

  .file-info__details {
    padding: 0 18px;
    line-height: 1.6;
    text-transform: none;
    color: #000 !important;

    .preview {
      height: 120px;
      background: #eef2f3;
      line-height: 120px;
      border-radius: 5px;
      border-bottom: none;
      margin-bottom: 20px;
      border: 1px solid #d1dae3 !important;
      position: relative;

      img {
        max-width: 240px;
        max-height: 100px !important;
        display: inline-block;
        top: 50%!important;
        left: 50%;
        position: absolute;
        transform: translate(-50%,-50%)!important;
      }
    }

    p {
      position: relative;
      min-height: 36px;
      margin: 15px 0 0;
      padding: 0 0 15px;
      border-bottom: 1px solid #d1dae3;
      line-height: 1.6 !important;
      color: #000 !important;

      &:last-child {
        border: none;
      }
    }
  }

  img.icon,
  svg.icon {
    top: 0;
    right: 0;
    transform: none;
    width: 18px;
    height: 18px;
    position: absolute;
  }
}


/* DAM integration */
#dam-iframe {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  z-index: -999;
  opacity: 0;

  &.dam-opened {
    z-index: 8;
    opacity: 1;
  }

  &.dam-inner-file-modal-opened {
    z-index: 9;
  }
}

.mobile-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
}

@media(max-width: 992px) {
  .dam-opened-main-wrapper {
    .mobile-alert {
      display: block;
    }
  }
}